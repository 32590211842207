import { useContext } from "react";
import { BaseDispatchContext } from "@/contexts/BaseContext";

export function useBaseActions() {
  const dispatch = useContext(BaseDispatchContext);

  if (!dispatch) {
    throw new Error("useBaseActions must be used within a BaseProvider");
  }

  const showModal = (modalType: string) => {
    dispatch({ type: "SHOW_MODAL", payload: { type: modalType } });
  };

  const showTargetModal = (modalType: string, targetId?: string | null) => {
    dispatch({ type: "SHOW_TARGET_MODAL", payload: { modalType, targetId } });
  };

  const closeModal = () => {
    dispatch({ type: "CLOSE_MODAL" });
  };

  const updateCoverImage = (payload: string) => {
    dispatch({ type: "UPDATE_COVER_IMAGE", payload });
  };

  return { showModal, showTargetModal, closeModal, updateCoverImage };
}
