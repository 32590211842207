import useSWR from "swr";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import useStorage from "@/hooks/useStorage";
import { SocialAuthLogin, UserMeView } from "@/api/generated";
import Api from "@/api";
import {
  KEY_AUTH_TOKEN,
  KEY_INVITATION_CODE,
  KEY_LAST_VISIT_USER,
} from "@/lib/storageKeys";
import { useBaseActions } from "@/hooks/useBaseActions";

export const useAuth = ({
  middleware,
  redirectIfAuthenticated,
}: {
  middleware: string;
  redirectIfAuthenticated?: string;
}) => {
  const router = useRouter();
  const { updateCoverImage } = useBaseActions();
  const { setLocalItem, getLocalItem, removeLocalItem, getSessionItem } =
    useStorage();
  const [authToken, setAuthToken] = useState<string | null>(null);
  const [authorized, setAuthorized] = useState(false);
  const [errorCount, setErrorCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const authHeader = { headers: { Authorization: "Bearer " + authToken } };

  const MAX_RETRIES = 5;

  const {
    data: user,
    error,
    mutate,
  } = useSWR<UserMeView, Error>(
    errorCount < MAX_RETRIES && authToken !== null ? "/api/users/me" : null,
    () => {
      return Api.Me.getApiUsersMeShow(authHeader).then((res) => {
        setIsLoading(false);
        return res.data;
      });
    },
    {
      onError: () => {
        setErrorCount(errorCount + 1);
        if (errorCount + 1 >= MAX_RETRIES) {
          setIsLoading(false);
        }
      },
    }
  );

  const login = (props: SocialAuthLogin) => {
    Api.Authentication.postApiAuthenticationSocialLogin(props)
      .then((res) => {
        const token = res.data?.auth_token || "";
        setAuthToken(token);
        setLocalItem(KEY_AUTH_TOKEN, token);
        setAuthorized(true);
      })
      .catch((error) => {
        console.log("login failed", error);
        logout("login_failed");
      });
  };

  const signup = (props: SocialAuthLogin) => {
    Api.Authentication.postApiAuthenticationSocialSignup(props)
      .then((res) => {
        const token = res.data?.auth_token || "";
        setAuthToken(token);
        setLocalItem(KEY_AUTH_TOKEN, token);
        setAuthorized(true);
      })
      .catch((error) => {
        console.log("signup failed", error);
        logout("signup_failed");
      });
  };

  const logout = (error = "") => {
    removeLocalItem(KEY_AUTH_TOKEN);
    window.location.href = "/login" + (error ? `?error=${error}` : "");
  };

  useEffect(() => {
    const token = getLocalItem(KEY_AUTH_TOKEN);
    setAuthToken(token);
  }, []);

  useEffect(() => {
    if (authorized && authToken) {
      mutate();
    }
  }, [authorized]);

  useEffect(() => {
    updateCoverImage(user?.cover_image || "coverImage01");

    if (middleware === "guest" && redirectIfAuthenticated && user) {
      if (!user.initialized) {
        router.push("/account/create");
        return;
      }

      if (getSessionItem(KEY_INVITATION_CODE)) {
        // router.push("/describe/invitation");
        router.push("/invite/" + getSessionItem(KEY_INVITATION_CODE));
        return;
      }

      if (
        getSessionItem(KEY_LAST_VISIT_USER) &&
        getSessionItem(KEY_LAST_VISIT_USER) !== user.username
      ) {
        router.push("/" + getSessionItem(KEY_LAST_VISIT_USER));
        return;
      }

      router.push(redirectIfAuthenticated);
    }
    if (middleware === "auth" && error) {
      // logout();
      window.location.href = "/login" + (error ? `?error=${error}` : "");
    }
  }, [user, error]);

  return {
    user,
    login,
    signup,
    error,
    logout,
    isLoading,
  };
};
