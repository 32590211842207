/* tslint:disable */
/* eslint-disable */
/**
 * Dscribe.me
 * Dscribe.me backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface CardRequestBody
 */
export interface CardRequestBody {
    /**
     * 
     * @type {number}
     * @memberof CardRequestBody
     */
    'x': number;
    /**
     * 
     * @type {number}
     * @memberof CardRequestBody
     */
    'y': number;
    /**
     * 
     * @type {number}
     * @memberof CardRequestBody
     */
    'w': number;
    /**
     * 
     * @type {number}
     * @memberof CardRequestBody
     */
    'h': number;
    /**
     * 
     * @type {string}
     * @memberof CardRequestBody
     */
    'layout': string;
    /**
     * 
     * @type {string}
     * @memberof CardRequestBody
     */
    'type': string;
    /**
     * 
     * @type {string}
     * @memberof CardRequestBody
     */
    'url'?: string;
    /**
     * 
     * @type {string}
     * @memberof CardRequestBody
     */
    'image'?: string;
    /**
     * 
     * @type {string}
     * @memberof CardRequestBody
     */
    'image_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CardRequestBody
     */
    'image_type'?: string;
    /**
     * 
     * @type {string}
     * @memberof CardRequestBody
     */
    'content'?: string;
    /**
     * 
     * @type {string}
     * @memberof CardRequestBody
     */
    'align'?: string;
    /**
     * 
     * @type {string}
     * @memberof CardRequestBody
     */
    'valign'?: string;
    /**
     * 
     * @type {string}
     * @memberof CardRequestBody
     */
    'bg_color'?: string;
    /**
     * 
     * @type {string}
     * @memberof CardRequestBody
     */
    'caption'?: string;
    /**
     * 
     * @type {string}
     * @memberof CardRequestBody
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof CardRequestBody
     */
    'account_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CardRequestBody
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface CardView
 */
export interface CardView {
    /**
     * 
     * @type {number}
     * @memberof CardView
     */
    'x'?: number;
    /**
     * 
     * @type {number}
     * @memberof CardView
     */
    'y'?: number;
    /**
     * 
     * @type {number}
     * @memberof CardView
     */
    'w'?: number;
    /**
     * 
     * @type {number}
     * @memberof CardView
     */
    'h'?: number;
    /**
     * 
     * @type {string}
     * @memberof CardView
     */
    'layout'?: string;
    /**
     * 
     * @type {string}
     * @memberof CardView
     */
    'type'?: string;
    /**
     * 
     * @type {CardViewContent}
     * @memberof CardView
     */
    'content'?: CardViewContent;
    /**
     * 
     * @type {number}
     * @memberof CardView
     */
    'created_at'?: number;
    /**
     * 
     * @type {number}
     * @memberof CardView
     */
    'updated_at'?: number;
    /**
     * 
     * @type {string}
     * @memberof CardView
     */
    'id'?: string;
}
/**
 * @type CardViewContent
 * @export
 */
export type CardViewContent = ImageCardView | LinkCardView | TextCardView;

/**
 * 
 * @export
 * @interface DescribeInvitationView
 */
export interface DescribeInvitationView {
    /**
     * 
     * @type {string}
     * @memberof DescribeInvitationView
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof DescribeInvitationView
     */
    'id'?: string;
}
/**
 * 
 * @export
 * @interface DescribeUserRequestBody
 */
export interface DescribeUserRequestBody {
    /**
     * 
     * @type {string}
     * @memberof DescribeUserRequestBody
     */
    'relationship': string;
    /**
     * 
     * @type {string}
     * @memberof DescribeUserRequestBody
     */
    'text': string;
}
/**
 * 
 * @export
 * @interface FullDescriptionView
 */
export interface FullDescriptionView {
    /**
     * 
     * @type {ShortUserView}
     * @memberof FullDescriptionView
     */
    'owner'?: ShortUserView;
    /**
     * 
     * @type {ShortUserView}
     * @memberof FullDescriptionView
     */
    'author'?: ShortUserView;
    /**
     * 
     * @type {boolean}
     * @memberof FullDescriptionView
     */
    'pinned'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof FullDescriptionView
     */
    'pinned_at'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FullDescriptionView
     */
    'edited_at'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FullDescriptionView
     */
    'updated_at'?: number;
    /**
     * 
     * @type {number}
     * @memberof FullDescriptionView
     */
    'created_at'?: number;
    /**
     * 
     * @type {number}
     * @memberof FullDescriptionView
     */
    'total_likes'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof FullDescriptionView
     */
    'me_liked'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FullDescriptionView
     */
    'relationship'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FullDescriptionView
     */
    'status'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FullDescriptionView
     */
    'text'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FullDescriptionView
     */
    'approved'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FullDescriptionView
     */
    'id'?: string;
}
/**
 * 
 * @export
 * @interface FullUserView
 */
export interface FullUserView {
    /**
     * 
     * @type {string}
     * @memberof FullUserView
     */
    'bio'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FullUserView
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FullUserView
     */
    'username'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FullUserView
     */
    'full_name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FullUserView
     */
    'avatar'?: string;
    /**
     * 
     * @type {string}
     * @memberof FullUserView
     */
    'cover_image'?: string;
}
/**
 * 
 * @export
 * @interface ImageCardView
 */
export interface ImageCardView {
    /**
     * 
     * @type {string}
     * @memberof ImageCardView
     */
    'image_type'?: string;
    /**
     * 
     * @type {string}
     * @memberof ImageCardView
     */
    'caption'?: string;
    /**
     * 
     * @type {string}
     * @memberof ImageCardView
     */
    'url'?: string;
    /**
     * 
     * @type {string}
     * @memberof ImageCardView
     */
    'image'?: string;
    /**
     * 
     * @type {string}
     * @memberof ImageCardView
     */
    'image_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ImageCardView
     */
    'id'?: string;
}
/**
 * 
 * @export
 * @interface ImageUrlUploadRequestBody
 */
export interface ImageUrlUploadRequestBody {
    /**
     * 
     * @type {string}
     * @memberof ImageUrlUploadRequestBody
     */
    'image_url': string | null;
}
/**
 * 
 * @export
 * @interface LinkCardView
 */
export interface LinkCardView {
    /**
     * 
     * @type {string}
     * @memberof LinkCardView
     */
    'source_site'?: string;
    /**
     * 
     * @type {string}
     * @memberof LinkCardView
     */
    'url'?: string;
    /**
     * 
     * @type {string}
     * @memberof LinkCardView
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof LinkCardView
     */
    'account_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof LinkCardView
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof LinkCardView
     */
    'image'?: string;
    /**
     * 
     * @type {string}
     * @memberof LinkCardView
     */
    'image_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof LinkCardView
     */
    'id'?: string;
}
/**
 * 
 * @export
 * @interface ListCardView
 */
export interface ListCardView {
    /**
     * 
     * @type {Array<CardView>}
     * @memberof ListCardView
     */
    'cards'?: Array<CardView>;
}
/**
 * 
 * @export
 * @interface ListDescriptionLikesView
 */
export interface ListDescriptionLikesView {
    /**
     * 
     * @type {Array<ShortDescriptionLikeView>}
     * @memberof ListDescriptionLikesView
     */
    'likes'?: Array<ShortDescriptionLikeView>;
}
/**
 * 
 * @export
 * @interface ListFullDescriptionsView
 */
export interface ListFullDescriptionsView {
    /**
     * 
     * @type {number}
     * @memberof ListFullDescriptionsView
     */
    'total'?: number;
    /**
     * 
     * @type {Array<FullDescriptionView>}
     * @memberof ListFullDescriptionsView
     */
    'data'?: Array<FullDescriptionView>;
}
/**
 * 
 * @export
 * @interface ListOwnerDescriptionsView
 */
export interface ListOwnerDescriptionsView {
    /**
     * 
     * @type {number}
     * @memberof ListOwnerDescriptionsView
     */
    'total'?: number;
    /**
     * 
     * @type {Array<OwnerDescriptionView>}
     * @memberof ListOwnerDescriptionsView
     */
    'data'?: Array<OwnerDescriptionView>;
}
/**
 * 
 * @export
 * @interface ListShortDescriptionsView
 */
export interface ListShortDescriptionsView {
    /**
     * 
     * @type {number}
     * @memberof ListShortDescriptionsView
     */
    'total'?: number;
    /**
     * 
     * @type {Array<ShortDescriptionView>}
     * @memberof ListShortDescriptionsView
     */
    'data'?: Array<ShortDescriptionView>;
}
/**
 * 
 * @export
 * @interface ListShortUsersView
 */
export interface ListShortUsersView {
    /**
     * 
     * @type {Array<ShortUserView>}
     * @memberof ListShortUsersView
     */
    'users'?: Array<ShortUserView>;
}
/**
 * 
 * @export
 * @interface ListUserSocialLinksView
 */
export interface ListUserSocialLinksView {
    /**
     * 
     * @type {Array<string>}
     * @memberof ListUserSocialLinksView
     */
    'social_links'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ListUserSocialLinksView
     */
    'twitter'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListUserSocialLinksView
     */
    'instagram'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListUserSocialLinksView
     */
    'tiktok'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListUserSocialLinksView
     */
    'youtube'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListUserSocialLinksView
     */
    'snapchat'?: string | null;
}
/**
 * 
 * @export
 * @interface OgpDataView
 */
export interface OgpDataView {
    /**
     * 
     * @type {string}
     * @memberof OgpDataView
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OgpDataView
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OgpDataView
     */
    'image'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OgpDataView
     */
    'source_site'?: string | null;
}
/**
 * 
 * @export
 * @interface OwnerDescriptionView
 */
export interface OwnerDescriptionView {
    /**
     * 
     * @type {boolean}
     * @memberof OwnerDescriptionView
     */
    'approved'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof OwnerDescriptionView
     */
    'approved_at'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof OwnerDescriptionView
     */
    'read'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof OwnerDescriptionView
     */
    'read_at'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof OwnerDescriptionView
     */
    'edit_requested_at'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof OwnerDescriptionView
     */
    'edit_submitted_at'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof OwnerDescriptionView
     */
    'replied'?: boolean;
    /**
     * 
     * @type {ShortUserView}
     * @memberof OwnerDescriptionView
     */
    'owner'?: ShortUserView;
    /**
     * 
     * @type {ShortUserView}
     * @memberof OwnerDescriptionView
     */
    'author'?: ShortUserView;
    /**
     * 
     * @type {boolean}
     * @memberof OwnerDescriptionView
     */
    'pinned'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof OwnerDescriptionView
     */
    'pinned_at'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof OwnerDescriptionView
     */
    'edited_at'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof OwnerDescriptionView
     */
    'updated_at'?: number;
    /**
     * 
     * @type {number}
     * @memberof OwnerDescriptionView
     */
    'created_at'?: number;
    /**
     * 
     * @type {number}
     * @memberof OwnerDescriptionView
     */
    'total_likes'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof OwnerDescriptionView
     */
    'me_liked'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof OwnerDescriptionView
     */
    'relationship'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OwnerDescriptionView
     */
    'status'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OwnerDescriptionView
     */
    'text'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OwnerDescriptionView
     */
    'id'?: string;
}
/**
 * 
 * @export
 * @interface PaginatedFullDescriptionsView
 */
export interface PaginatedFullDescriptionsView {
    /**
     * 
     * @type {Array<FullDescriptionView>}
     * @memberof PaginatedFullDescriptionsView
     */
    'descriptions'?: Array<FullDescriptionView>;
}
/**
 * 
 * @export
 * @interface PaginatedShortDescriptionsView
 */
export interface PaginatedShortDescriptionsView {
    /**
     * 
     * @type {Array<ShortDescriptionView>}
     * @memberof PaginatedShortDescriptionsView
     */
    'descriptions'?: Array<ShortDescriptionView>;
}
/**
 * 
 * @export
 * @interface SearchRequestBody
 */
export interface SearchRequestBody {
    /**
     * 
     * @type {string}
     * @memberof SearchRequestBody
     */
    'username'?: string;
}
/**
 * 
 * @export
 * @interface ShortDescriptionLikeView
 */
export interface ShortDescriptionLikeView {
    /**
     * 
     * @type {string}
     * @memberof ShortDescriptionLikeView
     */
    'description_id'?: string;
    /**
     * 
     * @type {ShortUserView}
     * @memberof ShortDescriptionLikeView
     */
    'author'?: ShortUserView;
    /**
     * 
     * @type {number}
     * @memberof ShortDescriptionLikeView
     */
    'created_at'?: number;
    /**
     * 
     * @type {number}
     * @memberof ShortDescriptionLikeView
     */
    'updated_at'?: number;
    /**
     * 
     * @type {string}
     * @memberof ShortDescriptionLikeView
     */
    'id'?: string;
}
/**
 * 
 * @export
 * @interface ShortDescriptionView
 */
export interface ShortDescriptionView {
    /**
     * 
     * @type {ShortUserView}
     * @memberof ShortDescriptionView
     */
    'owner'?: ShortUserView;
    /**
     * 
     * @type {ShortUserView}
     * @memberof ShortDescriptionView
     */
    'author'?: ShortUserView;
    /**
     * 
     * @type {boolean}
     * @memberof ShortDescriptionView
     */
    'pinned'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ShortDescriptionView
     */
    'pinned_at'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ShortDescriptionView
     */
    'edited_at'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ShortDescriptionView
     */
    'updated_at'?: number;
    /**
     * 
     * @type {number}
     * @memberof ShortDescriptionView
     */
    'created_at'?: number;
    /**
     * 
     * @type {number}
     * @memberof ShortDescriptionView
     */
    'total_likes'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ShortDescriptionView
     */
    'me_liked'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ShortDescriptionView
     */
    'relationship'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ShortDescriptionView
     */
    'status'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ShortDescriptionView
     */
    'text'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ShortDescriptionView
     */
    'approved'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ShortDescriptionView
     */
    'id'?: string;
}
/**
 * 
 * @export
 * @interface ShortImageView
 */
export interface ShortImageView {
    /**
     * 
     * @type {string}
     * @memberof ShortImageView
     */
    'url'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ShortImageView
     */
    'original_file_name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ShortImageView
     */
    'image_type'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ShortImageView
     */
    'width'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ShortImageView
     */
    'height'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ShortImageView
     */
    'size'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ShortImageView
     */
    'id'?: string;
}
/**
 * 
 * @export
 * @interface ShortUserSocialLinkView
 */
export interface ShortUserSocialLinkView {
    /**
     * 
     * @type {string}
     * @memberof ShortUserSocialLinkView
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ShortUserSocialLinkView
     */
    'value'?: string;
    /**
     * 
     * @type {string}
     * @memberof ShortUserSocialLinkView
     */
    'id'?: string;
}
/**
 * 
 * @export
 * @interface ShortUserView
 */
export interface ShortUserView {
    /**
     * 
     * @type {string}
     * @memberof ShortUserView
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ShortUserView
     */
    'username'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ShortUserView
     */
    'full_name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ShortUserView
     */
    'avatar'?: string;
    /**
     * 
     * @type {string}
     * @memberof ShortUserView
     */
    'cover_image'?: string;
}
/**
 * 
 * @export
 * @interface SocialAuthLogin
 */
export interface SocialAuthLogin {
    /**
     * 
     * @type {string}
     * @memberof SocialAuthLogin
     */
    'type': SocialAuthLoginTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof SocialAuthLogin
     */
    'token': string;
    /**
     * 
     * @type {string}
     * @memberof SocialAuthLogin
     */
    'invitation_code'?: string;
}

export const SocialAuthLoginTypeEnum = {
    Twitter: 'twitter',
    Instagram: 'instagram',
    Facebook: 'facebook',
    Google: 'google',
    Snapchat: 'snapchat'
} as const;

export type SocialAuthLoginTypeEnum = typeof SocialAuthLoginTypeEnum[keyof typeof SocialAuthLoginTypeEnum];

/**
 * 
 * @export
 * @interface SocialLoginView
 */
export interface SocialLoginView {
    /**
     * 
     * @type {string}
     * @memberof SocialLoginView
     */
    'auth_token'?: string;
}
/**
 * 
 * @export
 * @interface TextCardView
 */
export interface TextCardView {
    /**
     * 
     * @type {string}
     * @memberof TextCardView
     */
    'content'?: string;
    /**
     * 
     * @type {string}
     * @memberof TextCardView
     */
    'url'?: string;
    /**
     * 
     * @type {string}
     * @memberof TextCardView
     */
    'align'?: string;
    /**
     * 
     * @type {string}
     * @memberof TextCardView
     */
    'valign'?: string;
    /**
     * 
     * @type {string}
     * @memberof TextCardView
     */
    'bg_color'?: string;
    /**
     * 
     * @type {string}
     * @memberof TextCardView
     */
    'id'?: string;
}
/**
 * 
 * @export
 * @interface UpdateUserProfileRequestBody
 */
export interface UpdateUserProfileRequestBody {
    /**
     * 
     * @type {boolean}
     * @memberof UpdateUserProfileRequestBody
     */
    'initialized': boolean | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserProfileRequestBody
     */
    'username': string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserProfileRequestBody
     */
    'full_name': string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserProfileRequestBody
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserProfileRequestBody
     */
    'bio': string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserProfileRequestBody
     */
    'cover_image'?: string | null;
}
/**
 * 
 * @export
 * @interface UserMeView
 */
export interface UserMeView {
    /**
     * 
     * @type {boolean}
     * @memberof UserMeView
     */
    'initialized'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserMeView
     */
    'invited'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserMeView
     */
    'has_notification'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserMeView
     */
    'bio'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserMeView
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserMeView
     */
    'username'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserMeView
     */
    'full_name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserMeView
     */
    'avatar'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserMeView
     */
    'cover_image'?: string;
}
/**
 * 
 * @export
 * @interface UserSocialLinkRequestBody
 */
export interface UserSocialLinkRequestBody {
    /**
     * 
     * @type {string}
     * @memberof UserSocialLinkRequestBody
     */
    'name': string | null;
    /**
     * 
     * @type {string}
     * @memberof UserSocialLinkRequestBody
     */
    'value': string | null;
}

/**
 * AuthenticationApi - axios parameter creator
 * @export
 */
export const AuthenticationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {SocialAuthLogin} [socialAuthLogin] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiAuthenticationSocialLogin: async (socialAuthLogin?: SocialAuthLogin, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/authentication/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(socialAuthLogin, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SocialAuthLogin} [socialAuthLogin] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiAuthenticationSocialSignup: async (socialAuthLogin?: SocialAuthLogin, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/authentication/signup`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(socialAuthLogin, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthenticationApi - functional programming interface
 * @export
 */
export const AuthenticationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthenticationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {SocialAuthLogin} [socialAuthLogin] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postApiAuthenticationSocialLogin(socialAuthLogin?: SocialAuthLogin, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SocialLoginView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postApiAuthenticationSocialLogin(socialAuthLogin, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SocialAuthLogin} [socialAuthLogin] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postApiAuthenticationSocialSignup(socialAuthLogin?: SocialAuthLogin, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SocialLoginView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postApiAuthenticationSocialSignup(socialAuthLogin, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuthenticationApi - factory interface
 * @export
 */
export const AuthenticationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthenticationApiFp(configuration)
    return {
        /**
         * 
         * @param {SocialAuthLogin} [socialAuthLogin] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiAuthenticationSocialLogin(socialAuthLogin?: SocialAuthLogin, options?: any): AxiosPromise<SocialLoginView> {
            return localVarFp.postApiAuthenticationSocialLogin(socialAuthLogin, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SocialAuthLogin} [socialAuthLogin] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiAuthenticationSocialSignup(socialAuthLogin?: SocialAuthLogin, options?: any): AxiosPromise<SocialLoginView> {
            return localVarFp.postApiAuthenticationSocialSignup(socialAuthLogin, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthenticationApi - object-oriented interface
 * @export
 * @class AuthenticationApi
 * @extends {BaseAPI}
 */
export class AuthenticationApi extends BaseAPI {
    /**
     * 
     * @param {SocialAuthLogin} [socialAuthLogin] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public postApiAuthenticationSocialLogin(socialAuthLogin?: SocialAuthLogin, options?: AxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).postApiAuthenticationSocialLogin(socialAuthLogin, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SocialAuthLogin} [socialAuthLogin] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public postApiAuthenticationSocialSignup(socialAuthLogin?: SocialAuthLogin, options?: AxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).postApiAuthenticationSocialSignup(socialAuthLogin, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ImageUrlUploadRequestBody} [imageUrlUploadRequestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiImageUploadFromUrlPost: async (imageUrlUploadRequestBody?: ImageUrlUploadRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/image/uploadFromUrl`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(imageUrlUploadRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {File} [image] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiImageUpload: async (image?: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/image/upload`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (image !== undefined) { 
                localVarFormParams.append('image', image as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {ImageUrlUploadRequestBody} [imageUrlUploadRequestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiImageUploadFromUrlPost(imageUrlUploadRequestBody?: ImageUrlUploadRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShortImageView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiImageUploadFromUrlPost(imageUrlUploadRequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {File} [image] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postApiImageUpload(image?: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShortImageView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postApiImageUpload(image, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * 
         * @param {ImageUrlUploadRequestBody} [imageUrlUploadRequestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiImageUploadFromUrlPost(imageUrlUploadRequestBody?: ImageUrlUploadRequestBody, options?: any): AxiosPromise<ShortImageView> {
            return localVarFp.apiImageUploadFromUrlPost(imageUrlUploadRequestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {File} [image] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiImageUpload(image?: File, options?: any): AxiosPromise<ShortImageView> {
            return localVarFp.postApiImageUpload(image, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * 
     * @param {ImageUrlUploadRequestBody} [imageUrlUploadRequestBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiImageUploadFromUrlPost(imageUrlUploadRequestBody?: ImageUrlUploadRequestBody, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiImageUploadFromUrlPost(imageUrlUploadRequestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {File} [image] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public postApiImageUpload(image?: File, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).postApiImageUpload(image, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DescriptionsApi - axios parameter creator
 * @export
 */
export const DescriptionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Fetch all likes for description
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiDescriptionLikesList: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getApiDescriptionLikesList', 'id', id)
            const localVarPath = `/api/descriptions/{id}/likes`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Fetch all descriptions by username
         * @param {string} username 
         * @param {number} [offset] The number of items to skip before starting to collect the result set
         * @param {number} [limit] The numbers of items to return
         * @param {string} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiDescriptionsListByUsername: async (username: string, offset?: number, limit?: number, sort?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'username' is not null or undefined
            assertParamExists('getApiDescriptionsListByUsername', 'username', username)
            const localVarPath = `/api/descriptions/list/{username}`
                .replace(`{${"username"}}`, encodeURIComponent(String(username)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Show description (doesnt update read) public
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiDescriptionsShow: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getApiDescriptionsShow', 'id', id)
            const localVarPath = `/api/descriptions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Search for description by full_name or username
         * @param {SearchRequestBody} [searchRequestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiDescriptionsSearchDescriptions: async (searchRequestBody?: SearchRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/descriptions/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(searchRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DescriptionsApi - functional programming interface
 * @export
 */
export const DescriptionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DescriptionsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Fetch all likes for description
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiDescriptionLikesList(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListDescriptionLikesView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiDescriptionLikesList(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Fetch all descriptions by username
         * @param {string} username 
         * @param {number} [offset] The number of items to skip before starting to collect the result set
         * @param {number} [limit] The numbers of items to return
         * @param {string} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiDescriptionsListByUsername(username: string, offset?: number, limit?: number, sort?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListShortDescriptionsView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiDescriptionsListByUsername(username, offset, limit, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Show description (doesnt update read) public
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiDescriptionsShow(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShortDescriptionView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiDescriptionsShow(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Search for description by full_name or username
         * @param {SearchRequestBody} [searchRequestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postApiDescriptionsSearchDescriptions(searchRequestBody?: SearchRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedShortDescriptionsView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postApiDescriptionsSearchDescriptions(searchRequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DescriptionsApi - factory interface
 * @export
 */
export const DescriptionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DescriptionsApiFp(configuration)
    return {
        /**
         * 
         * @summary Fetch all likes for description
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiDescriptionLikesList(id: string, options?: any): AxiosPromise<ListDescriptionLikesView> {
            return localVarFp.getApiDescriptionLikesList(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Fetch all descriptions by username
         * @param {string} username 
         * @param {number} [offset] The number of items to skip before starting to collect the result set
         * @param {number} [limit] The numbers of items to return
         * @param {string} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiDescriptionsListByUsername(username: string, offset?: number, limit?: number, sort?: string, options?: any): AxiosPromise<ListShortDescriptionsView> {
            return localVarFp.getApiDescriptionsListByUsername(username, offset, limit, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Show description (doesnt update read) public
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiDescriptionsShow(id: string, options?: any): AxiosPromise<ShortDescriptionView> {
            return localVarFp.getApiDescriptionsShow(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Search for description by full_name or username
         * @param {SearchRequestBody} [searchRequestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiDescriptionsSearchDescriptions(searchRequestBody?: SearchRequestBody, options?: any): AxiosPromise<PaginatedShortDescriptionsView> {
            return localVarFp.postApiDescriptionsSearchDescriptions(searchRequestBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DescriptionsApi - object-oriented interface
 * @export
 * @class DescriptionsApi
 * @extends {BaseAPI}
 */
export class DescriptionsApi extends BaseAPI {
    /**
     * 
     * @summary Fetch all likes for description
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DescriptionsApi
     */
    public getApiDescriptionLikesList(id: string, options?: AxiosRequestConfig) {
        return DescriptionsApiFp(this.configuration).getApiDescriptionLikesList(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Fetch all descriptions by username
     * @param {string} username 
     * @param {number} [offset] The number of items to skip before starting to collect the result set
     * @param {number} [limit] The numbers of items to return
     * @param {string} [sort] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DescriptionsApi
     */
    public getApiDescriptionsListByUsername(username: string, offset?: number, limit?: number, sort?: string, options?: AxiosRequestConfig) {
        return DescriptionsApiFp(this.configuration).getApiDescriptionsListByUsername(username, offset, limit, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Show description (doesnt update read) public
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DescriptionsApi
     */
    public getApiDescriptionsShow(id: string, options?: AxiosRequestConfig) {
        return DescriptionsApiFp(this.configuration).getApiDescriptionsShow(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Search for description by full_name or username
     * @param {SearchRequestBody} [searchRequestBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DescriptionsApi
     */
    public postApiDescriptionsSearchDescriptions(searchRequestBody?: SearchRequestBody, options?: AxiosRequestConfig) {
        return DescriptionsApiFp(this.configuration).postApiDescriptionsSearchDescriptions(searchRequestBody, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DescriptionsMeApi - axios parameter creator
 * @export
 */
export const DescriptionsMeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete description about my wrote and description about the author
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteApiDescriptionsMeAuthorDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteApiDescriptionsMeAuthorDelete', 'id', id)
            const localVarPath = `/api/me/descriptions/author/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete description about me and description about the author
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteApiDescriptionsMeDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteApiDescriptionsMeDelete', 'id', id)
            const localVarPath = `/api/me/descriptions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Unlike a description
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteApiDescriptionsMeUnlike: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteApiDescriptionsMeUnlike', 'id', id)
            const localVarPath = `/api/me/descriptions/{id}/likes`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Fetch description about my wrote (includes detailed information)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiDescriptionsMeAuthorShow: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getApiDescriptionsMeAuthorShow', 'id', id)
            const localVarPath = `/api/me/descriptions/author/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Fetch all my descriptions
         * @param {number} [offset] The number of items to skip before starting to collect the result set
         * @param {number} [limit] The numbers of items to return
         * @param {string} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiDescriptionsMeList: async (offset?: number, limit?: number, sort?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/me/descriptions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Fetch all descriptions by username
         * @param {string} username 
         * @param {number} [offset] The number of items to skip before starting to collect the result set
         * @param {number} [limit] The numbers of items to return
         * @param {string} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiDescriptionsMeListByUsername: async (username: string, offset?: number, limit?: number, sort?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'username' is not null or undefined
            assertParamExists('getApiDescriptionsMeListByUsername', 'username', username)
            const localVarPath = `/api/me/descriptions/user/{username}`
                .replace(`{${"username"}}`, encodeURIComponent(String(username)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List described descriptions
         * @param {number} [offset] The number of items to skip before starting to collect the result set
         * @param {number} [limit] The numbers of items to return
         * @param {string} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiDescriptionsMeListDescribed: async (offset?: number, limit?: number, sort?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/me/descriptions/list/described`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List Notifications
         * @param {number} [offset] The number of items to skip before starting to collect the result set
         * @param {number} [limit] The numbers of items to return
         * @param {string} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiDescriptionsMeListNotifications: async (offset?: number, limit?: number, sort?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/me/descriptions/list/notifications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Fetch all mutual descriptions including drafts by username
         * @param {string} username 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiDescriptionsMeMutualListByUsername: async (username: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'username' is not null or undefined
            assertParamExists('getApiDescriptionsMeMutualListByUsername', 'username', username)
            const localVarPath = `/api/me/descriptions/user/{username}/mutual`
                .replace(`{${"username"}}`, encodeURIComponent(String(username)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Show replied description
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiDescriptionsMeRepliedDescription: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getApiDescriptionsMeRepliedDescription', 'id', id)
            const localVarPath = `/api/me/descriptions/{id}/replied`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Fetch description about me (includes detailed information)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiDescriptionsMeShow: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getApiDescriptionsMeShow', 'id', id)
            const localVarPath = `/api/me/descriptions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create new invitation code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiDescriptionsMeCreateInvitation: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/me/descriptions/invitation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Decline description of me
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiDescriptionsMeDecline: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('postApiDescriptionsMeDecline', 'id', id)
            const localVarPath = `/api/me/descriptions/{id}/decline`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Like a description
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiDescriptionsMeLike: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('postApiDescriptionsMeLike', 'id', id)
            const localVarPath = `/api/me/descriptions/{id}/likes`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} username 
         * @param {DescribeUserRequestBody} [describeUserRequestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiDescriptionsMePostDescribeBack: async (username: string, describeUserRequestBody?: DescribeUserRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'username' is not null or undefined
            assertParamExists('postApiDescriptionsMePostDescribeBack', 'username', username)
            const localVarPath = `/api/me/descriptions/back/{username}`
                .replace(`{${"username"}}`, encodeURIComponent(String(username)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(describeUserRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} code 
         * @param {DescribeUserRequestBody} [describeUserRequestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiDescriptionsMePostInvitation: async (code: string, describeUserRequestBody?: DescribeUserRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'code' is not null or undefined
            assertParamExists('postApiDescriptionsMePostInvitation', 'code', code)
            const localVarPath = `/api/me/descriptions/invitation/{code}`
                .replace(`{${"code"}}`, encodeURIComponent(String(code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(describeUserRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Publish each other\'s descriptions
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiDescriptionsMePublish: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('postApiDescriptionsMePublish', 'id', id)
            const localVarPath = `/api/me/descriptions/{id}/publish`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read description of me
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiDescriptionsMeRead: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('postApiDescriptionsMeRead', 'id', id)
            const localVarPath = `/api/me/descriptions/{id}/read`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Search for description by full_name or username
         * @param {SearchRequestBody} [searchRequestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiDescriptionsMeSearchDescriptions: async (searchRequestBody?: SearchRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/me/descriptions/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(searchRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Edit description if description requires edit
         * @param {string} id 
         * @param {DescribeUserRequestBody} [describeUserRequestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putApiDescriptionsMeEdit: async (id: string, describeUserRequestBody?: DescribeUserRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putApiDescriptionsMeEdit', 'id', id)
            const localVarPath = `/api/me/descriptions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(describeUserRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DescriptionsMeApi - functional programming interface
 * @export
 */
export const DescriptionsMeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DescriptionsMeApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Delete description about my wrote and description about the author
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteApiDescriptionsMeAuthorDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteApiDescriptionsMeAuthorDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete description about me and description about the author
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteApiDescriptionsMeDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteApiDescriptionsMeDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Unlike a description
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteApiDescriptionsMeUnlike(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteApiDescriptionsMeUnlike(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Fetch description about my wrote (includes detailed information)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiDescriptionsMeAuthorShow(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OwnerDescriptionView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiDescriptionsMeAuthorShow(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Fetch all my descriptions
         * @param {number} [offset] The number of items to skip before starting to collect the result set
         * @param {number} [limit] The numbers of items to return
         * @param {string} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiDescriptionsMeList(offset?: number, limit?: number, sort?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListOwnerDescriptionsView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiDescriptionsMeList(offset, limit, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Fetch all descriptions by username
         * @param {string} username 
         * @param {number} [offset] The number of items to skip before starting to collect the result set
         * @param {number} [limit] The numbers of items to return
         * @param {string} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiDescriptionsMeListByUsername(username: string, offset?: number, limit?: number, sort?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListFullDescriptionsView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiDescriptionsMeListByUsername(username, offset, limit, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List described descriptions
         * @param {number} [offset] The number of items to skip before starting to collect the result set
         * @param {number} [limit] The numbers of items to return
         * @param {string} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiDescriptionsMeListDescribed(offset?: number, limit?: number, sort?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListOwnerDescriptionsView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiDescriptionsMeListDescribed(offset, limit, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List Notifications
         * @param {number} [offset] The number of items to skip before starting to collect the result set
         * @param {number} [limit] The numbers of items to return
         * @param {string} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiDescriptionsMeListNotifications(offset?: number, limit?: number, sort?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListOwnerDescriptionsView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiDescriptionsMeListNotifications(offset, limit, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Fetch all mutual descriptions including drafts by username
         * @param {string} username 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiDescriptionsMeMutualListByUsername(username: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListFullDescriptionsView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiDescriptionsMeMutualListByUsername(username, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Show replied description
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiDescriptionsMeRepliedDescription(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FullDescriptionView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiDescriptionsMeRepliedDescription(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Fetch description about me (includes detailed information)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiDescriptionsMeShow(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OwnerDescriptionView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiDescriptionsMeShow(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create new invitation code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postApiDescriptionsMeCreateInvitation(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DescribeInvitationView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postApiDescriptionsMeCreateInvitation(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Decline description of me
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postApiDescriptionsMeDecline(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postApiDescriptionsMeDecline(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Like a description
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postApiDescriptionsMeLike(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postApiDescriptionsMeLike(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} username 
         * @param {DescribeUserRequestBody} [describeUserRequestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postApiDescriptionsMePostDescribeBack(username: string, describeUserRequestBody?: DescribeUserRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FullDescriptionView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postApiDescriptionsMePostDescribeBack(username, describeUserRequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} code 
         * @param {DescribeUserRequestBody} [describeUserRequestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postApiDescriptionsMePostInvitation(code: string, describeUserRequestBody?: DescribeUserRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FullDescriptionView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postApiDescriptionsMePostInvitation(code, describeUserRequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Publish each other\'s descriptions
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postApiDescriptionsMePublish(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postApiDescriptionsMePublish(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read description of me
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postApiDescriptionsMeRead(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postApiDescriptionsMeRead(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Search for description by full_name or username
         * @param {SearchRequestBody} [searchRequestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postApiDescriptionsMeSearchDescriptions(searchRequestBody?: SearchRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedFullDescriptionsView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postApiDescriptionsMeSearchDescriptions(searchRequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Edit description if description requires edit
         * @param {string} id 
         * @param {DescribeUserRequestBody} [describeUserRequestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putApiDescriptionsMeEdit(id: string, describeUserRequestBody?: DescribeUserRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FullDescriptionView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putApiDescriptionsMeEdit(id, describeUserRequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DescriptionsMeApi - factory interface
 * @export
 */
export const DescriptionsMeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DescriptionsMeApiFp(configuration)
    return {
        /**
         * 
         * @summary Delete description about my wrote and description about the author
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteApiDescriptionsMeAuthorDelete(id: string, options?: any): AxiosPromise<object> {
            return localVarFp.deleteApiDescriptionsMeAuthorDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete description about me and description about the author
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteApiDescriptionsMeDelete(id: string, options?: any): AxiosPromise<object> {
            return localVarFp.deleteApiDescriptionsMeDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Unlike a description
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteApiDescriptionsMeUnlike(id: string, options?: any): AxiosPromise<object> {
            return localVarFp.deleteApiDescriptionsMeUnlike(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Fetch description about my wrote (includes detailed information)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiDescriptionsMeAuthorShow(id: string, options?: any): AxiosPromise<OwnerDescriptionView> {
            return localVarFp.getApiDescriptionsMeAuthorShow(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Fetch all my descriptions
         * @param {number} [offset] The number of items to skip before starting to collect the result set
         * @param {number} [limit] The numbers of items to return
         * @param {string} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiDescriptionsMeList(offset?: number, limit?: number, sort?: string, options?: any): AxiosPromise<ListOwnerDescriptionsView> {
            return localVarFp.getApiDescriptionsMeList(offset, limit, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Fetch all descriptions by username
         * @param {string} username 
         * @param {number} [offset] The number of items to skip before starting to collect the result set
         * @param {number} [limit] The numbers of items to return
         * @param {string} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiDescriptionsMeListByUsername(username: string, offset?: number, limit?: number, sort?: string, options?: any): AxiosPromise<ListFullDescriptionsView> {
            return localVarFp.getApiDescriptionsMeListByUsername(username, offset, limit, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List described descriptions
         * @param {number} [offset] The number of items to skip before starting to collect the result set
         * @param {number} [limit] The numbers of items to return
         * @param {string} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiDescriptionsMeListDescribed(offset?: number, limit?: number, sort?: string, options?: any): AxiosPromise<ListOwnerDescriptionsView> {
            return localVarFp.getApiDescriptionsMeListDescribed(offset, limit, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List Notifications
         * @param {number} [offset] The number of items to skip before starting to collect the result set
         * @param {number} [limit] The numbers of items to return
         * @param {string} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiDescriptionsMeListNotifications(offset?: number, limit?: number, sort?: string, options?: any): AxiosPromise<ListOwnerDescriptionsView> {
            return localVarFp.getApiDescriptionsMeListNotifications(offset, limit, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Fetch all mutual descriptions including drafts by username
         * @param {string} username 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiDescriptionsMeMutualListByUsername(username: string, options?: any): AxiosPromise<ListFullDescriptionsView> {
            return localVarFp.getApiDescriptionsMeMutualListByUsername(username, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Show replied description
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiDescriptionsMeRepliedDescription(id: string, options?: any): AxiosPromise<FullDescriptionView> {
            return localVarFp.getApiDescriptionsMeRepliedDescription(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Fetch description about me (includes detailed information)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiDescriptionsMeShow(id: string, options?: any): AxiosPromise<OwnerDescriptionView> {
            return localVarFp.getApiDescriptionsMeShow(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create new invitation code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiDescriptionsMeCreateInvitation(options?: any): AxiosPromise<DescribeInvitationView> {
            return localVarFp.postApiDescriptionsMeCreateInvitation(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Decline description of me
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiDescriptionsMeDecline(id: string, options?: any): AxiosPromise<object> {
            return localVarFp.postApiDescriptionsMeDecline(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Like a description
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiDescriptionsMeLike(id: string, options?: any): AxiosPromise<object> {
            return localVarFp.postApiDescriptionsMeLike(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} username 
         * @param {DescribeUserRequestBody} [describeUserRequestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiDescriptionsMePostDescribeBack(username: string, describeUserRequestBody?: DescribeUserRequestBody, options?: any): AxiosPromise<FullDescriptionView> {
            return localVarFp.postApiDescriptionsMePostDescribeBack(username, describeUserRequestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} code 
         * @param {DescribeUserRequestBody} [describeUserRequestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiDescriptionsMePostInvitation(code: string, describeUserRequestBody?: DescribeUserRequestBody, options?: any): AxiosPromise<FullDescriptionView> {
            return localVarFp.postApiDescriptionsMePostInvitation(code, describeUserRequestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Publish each other\'s descriptions
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiDescriptionsMePublish(id: string, options?: any): AxiosPromise<object> {
            return localVarFp.postApiDescriptionsMePublish(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read description of me
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiDescriptionsMeRead(id: string, options?: any): AxiosPromise<object> {
            return localVarFp.postApiDescriptionsMeRead(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Search for description by full_name or username
         * @param {SearchRequestBody} [searchRequestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiDescriptionsMeSearchDescriptions(searchRequestBody?: SearchRequestBody, options?: any): AxiosPromise<PaginatedFullDescriptionsView> {
            return localVarFp.postApiDescriptionsMeSearchDescriptions(searchRequestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Edit description if description requires edit
         * @param {string} id 
         * @param {DescribeUserRequestBody} [describeUserRequestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putApiDescriptionsMeEdit(id: string, describeUserRequestBody?: DescribeUserRequestBody, options?: any): AxiosPromise<FullDescriptionView> {
            return localVarFp.putApiDescriptionsMeEdit(id, describeUserRequestBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DescriptionsMeApi - object-oriented interface
 * @export
 * @class DescriptionsMeApi
 * @extends {BaseAPI}
 */
export class DescriptionsMeApi extends BaseAPI {
    /**
     * 
     * @summary Delete description about my wrote and description about the author
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DescriptionsMeApi
     */
    public deleteApiDescriptionsMeAuthorDelete(id: string, options?: AxiosRequestConfig) {
        return DescriptionsMeApiFp(this.configuration).deleteApiDescriptionsMeAuthorDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete description about me and description about the author
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DescriptionsMeApi
     */
    public deleteApiDescriptionsMeDelete(id: string, options?: AxiosRequestConfig) {
        return DescriptionsMeApiFp(this.configuration).deleteApiDescriptionsMeDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Unlike a description
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DescriptionsMeApi
     */
    public deleteApiDescriptionsMeUnlike(id: string, options?: AxiosRequestConfig) {
        return DescriptionsMeApiFp(this.configuration).deleteApiDescriptionsMeUnlike(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Fetch description about my wrote (includes detailed information)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DescriptionsMeApi
     */
    public getApiDescriptionsMeAuthorShow(id: string, options?: AxiosRequestConfig) {
        return DescriptionsMeApiFp(this.configuration).getApiDescriptionsMeAuthorShow(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Fetch all my descriptions
     * @param {number} [offset] The number of items to skip before starting to collect the result set
     * @param {number} [limit] The numbers of items to return
     * @param {string} [sort] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DescriptionsMeApi
     */
    public getApiDescriptionsMeList(offset?: number, limit?: number, sort?: string, options?: AxiosRequestConfig) {
        return DescriptionsMeApiFp(this.configuration).getApiDescriptionsMeList(offset, limit, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Fetch all descriptions by username
     * @param {string} username 
     * @param {number} [offset] The number of items to skip before starting to collect the result set
     * @param {number} [limit] The numbers of items to return
     * @param {string} [sort] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DescriptionsMeApi
     */
    public getApiDescriptionsMeListByUsername(username: string, offset?: number, limit?: number, sort?: string, options?: AxiosRequestConfig) {
        return DescriptionsMeApiFp(this.configuration).getApiDescriptionsMeListByUsername(username, offset, limit, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List described descriptions
     * @param {number} [offset] The number of items to skip before starting to collect the result set
     * @param {number} [limit] The numbers of items to return
     * @param {string} [sort] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DescriptionsMeApi
     */
    public getApiDescriptionsMeListDescribed(offset?: number, limit?: number, sort?: string, options?: AxiosRequestConfig) {
        return DescriptionsMeApiFp(this.configuration).getApiDescriptionsMeListDescribed(offset, limit, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List Notifications
     * @param {number} [offset] The number of items to skip before starting to collect the result set
     * @param {number} [limit] The numbers of items to return
     * @param {string} [sort] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DescriptionsMeApi
     */
    public getApiDescriptionsMeListNotifications(offset?: number, limit?: number, sort?: string, options?: AxiosRequestConfig) {
        return DescriptionsMeApiFp(this.configuration).getApiDescriptionsMeListNotifications(offset, limit, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Fetch all mutual descriptions including drafts by username
     * @param {string} username 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DescriptionsMeApi
     */
    public getApiDescriptionsMeMutualListByUsername(username: string, options?: AxiosRequestConfig) {
        return DescriptionsMeApiFp(this.configuration).getApiDescriptionsMeMutualListByUsername(username, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Show replied description
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DescriptionsMeApi
     */
    public getApiDescriptionsMeRepliedDescription(id: string, options?: AxiosRequestConfig) {
        return DescriptionsMeApiFp(this.configuration).getApiDescriptionsMeRepliedDescription(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Fetch description about me (includes detailed information)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DescriptionsMeApi
     */
    public getApiDescriptionsMeShow(id: string, options?: AxiosRequestConfig) {
        return DescriptionsMeApiFp(this.configuration).getApiDescriptionsMeShow(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create new invitation code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DescriptionsMeApi
     */
    public postApiDescriptionsMeCreateInvitation(options?: AxiosRequestConfig) {
        return DescriptionsMeApiFp(this.configuration).postApiDescriptionsMeCreateInvitation(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Decline description of me
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DescriptionsMeApi
     */
    public postApiDescriptionsMeDecline(id: string, options?: AxiosRequestConfig) {
        return DescriptionsMeApiFp(this.configuration).postApiDescriptionsMeDecline(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Like a description
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DescriptionsMeApi
     */
    public postApiDescriptionsMeLike(id: string, options?: AxiosRequestConfig) {
        return DescriptionsMeApiFp(this.configuration).postApiDescriptionsMeLike(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} username 
     * @param {DescribeUserRequestBody} [describeUserRequestBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DescriptionsMeApi
     */
    public postApiDescriptionsMePostDescribeBack(username: string, describeUserRequestBody?: DescribeUserRequestBody, options?: AxiosRequestConfig) {
        return DescriptionsMeApiFp(this.configuration).postApiDescriptionsMePostDescribeBack(username, describeUserRequestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} code 
     * @param {DescribeUserRequestBody} [describeUserRequestBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DescriptionsMeApi
     */
    public postApiDescriptionsMePostInvitation(code: string, describeUserRequestBody?: DescribeUserRequestBody, options?: AxiosRequestConfig) {
        return DescriptionsMeApiFp(this.configuration).postApiDescriptionsMePostInvitation(code, describeUserRequestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Publish each other\'s descriptions
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DescriptionsMeApi
     */
    public postApiDescriptionsMePublish(id: string, options?: AxiosRequestConfig) {
        return DescriptionsMeApiFp(this.configuration).postApiDescriptionsMePublish(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read description of me
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DescriptionsMeApi
     */
    public postApiDescriptionsMeRead(id: string, options?: AxiosRequestConfig) {
        return DescriptionsMeApiFp(this.configuration).postApiDescriptionsMeRead(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Search for description by full_name or username
     * @param {SearchRequestBody} [searchRequestBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DescriptionsMeApi
     */
    public postApiDescriptionsMeSearchDescriptions(searchRequestBody?: SearchRequestBody, options?: AxiosRequestConfig) {
        return DescriptionsMeApiFp(this.configuration).postApiDescriptionsMeSearchDescriptions(searchRequestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Edit description if description requires edit
     * @param {string} id 
     * @param {DescribeUserRequestBody} [describeUserRequestBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DescriptionsMeApi
     */
    public putApiDescriptionsMeEdit(id: string, describeUserRequestBody?: DescribeUserRequestBody, options?: AxiosRequestConfig) {
        return DescriptionsMeApiFp(this.configuration).putApiDescriptionsMeEdit(id, describeUserRequestBody, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * GridLayoutApi - axios parameter creator
 * @export
 */
export const GridLayoutApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary List all cards for the user
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiGridLayoutsShow: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getApiGridLayoutsShow', 'id', id)
            const localVarPath = `/api/grid_layouts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GridLayoutApi - functional programming interface
 * @export
 */
export const GridLayoutApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GridLayoutApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary List all cards for the user
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiGridLayoutsShow(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListCardView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiGridLayoutsShow(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GridLayoutApi - factory interface
 * @export
 */
export const GridLayoutApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GridLayoutApiFp(configuration)
    return {
        /**
         * 
         * @summary List all cards for the user
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiGridLayoutsShow(id: string, options?: any): AxiosPromise<ListCardView> {
            return localVarFp.getApiGridLayoutsShow(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GridLayoutApi - object-oriented interface
 * @export
 * @class GridLayoutApi
 * @extends {BaseAPI}
 */
export class GridLayoutApi extends BaseAPI {
    /**
     * 
     * @summary List all cards for the user
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GridLayoutApi
     */
    public getApiGridLayoutsShow(id: string, options?: AxiosRequestConfig) {
        return GridLayoutApiFp(this.configuration).getApiGridLayoutsShow(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * GridLayoutMeApi - axios parameter creator
 * @export
 */
export const GridLayoutMeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete a card
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteApiGridLayoutsMeDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteApiGridLayoutsMeDelete', 'id', id)
            const localVarPath = `/api/me/grid_layouts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a new card
         * @param {CardRequestBody} [cardRequestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiGridLayoutsMeCards: async (cardRequestBody?: CardRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/me/grid_layouts/cards`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(cardRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a card
         * @param {string} id 
         * @param {CardRequestBody} [cardRequestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putApiGridLayoutsMeUpdate: async (id: string, cardRequestBody?: CardRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putApiGridLayoutsMeUpdate', 'id', id)
            const localVarPath = `/api/me/grid_layouts/cards/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(cardRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GridLayoutMeApi - functional programming interface
 * @export
 */
export const GridLayoutMeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GridLayoutMeApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Delete a card
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteApiGridLayoutsMeDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteApiGridLayoutsMeDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a new card
         * @param {CardRequestBody} [cardRequestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postApiGridLayoutsMeCards(cardRequestBody?: CardRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CardView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postApiGridLayoutsMeCards(cardRequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a card
         * @param {string} id 
         * @param {CardRequestBody} [cardRequestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putApiGridLayoutsMeUpdate(id: string, cardRequestBody?: CardRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CardView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putApiGridLayoutsMeUpdate(id, cardRequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GridLayoutMeApi - factory interface
 * @export
 */
export const GridLayoutMeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GridLayoutMeApiFp(configuration)
    return {
        /**
         * 
         * @summary Delete a card
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteApiGridLayoutsMeDelete(id: string, options?: any): AxiosPromise<object> {
            return localVarFp.deleteApiGridLayoutsMeDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a new card
         * @param {CardRequestBody} [cardRequestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiGridLayoutsMeCards(cardRequestBody?: CardRequestBody, options?: any): AxiosPromise<CardView> {
            return localVarFp.postApiGridLayoutsMeCards(cardRequestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a card
         * @param {string} id 
         * @param {CardRequestBody} [cardRequestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putApiGridLayoutsMeUpdate(id: string, cardRequestBody?: CardRequestBody, options?: any): AxiosPromise<CardView> {
            return localVarFp.putApiGridLayoutsMeUpdate(id, cardRequestBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GridLayoutMeApi - object-oriented interface
 * @export
 * @class GridLayoutMeApi
 * @extends {BaseAPI}
 */
export class GridLayoutMeApi extends BaseAPI {
    /**
     * 
     * @summary Delete a card
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GridLayoutMeApi
     */
    public deleteApiGridLayoutsMeDelete(id: string, options?: AxiosRequestConfig) {
        return GridLayoutMeApiFp(this.configuration).deleteApiGridLayoutsMeDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a new card
     * @param {CardRequestBody} [cardRequestBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GridLayoutMeApi
     */
    public postApiGridLayoutsMeCards(cardRequestBody?: CardRequestBody, options?: AxiosRequestConfig) {
        return GridLayoutMeApiFp(this.configuration).postApiGridLayoutsMeCards(cardRequestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a card
     * @param {string} id 
     * @param {CardRequestBody} [cardRequestBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GridLayoutMeApi
     */
    public putApiGridLayoutsMeUpdate(id: string, cardRequestBody?: CardRequestBody, options?: AxiosRequestConfig) {
        return GridLayoutMeApiFp(this.configuration).putApiGridLayoutsMeUpdate(id, cardRequestBody, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MeApi - axios parameter creator
 * @export
 */
export const MeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete profile picture
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteApiUsersMePhotoDelete: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/me/photo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a social link by type
         * @param {string} type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteApiUsersMeSocialLinksDelete: async (type: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'type' is not null or undefined
            assertParamExists('deleteApiUsersMeSocialLinksDelete', 'type', type)
            const localVarPath = `/api/me/social_links/{type}`
                .replace(`{${"type"}}`, encodeURIComponent(String(type)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Fetch my user information
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiUsersMeShow: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiUsersMeShowInvitation: async (code: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'code' is not null or undefined
            assertParamExists('getApiUsersMeShowInvitation', 'code', code)
            const localVarPath = `/api/me/invitation/{code}`
                .replace(`{${"code"}}`, encodeURIComponent(String(code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Fetch my social links
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiUsersMeSocialLinksList: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/me/social_links`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upload new profile picture
         * @param {File} [image] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiUsersMePhotoUpdate: async (image?: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/me/photo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (image !== undefined) { 
                localVarFormParams.append('image', image as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add new social link
         * @param {UserSocialLinkRequestBody} [userSocialLinkRequestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiUsersMeSocialLinksCreate: async (userSocialLinkRequestBody?: UserSocialLinkRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/me/social_links`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userSocialLinkRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a social link by type
         * @param {string} type 
         * @param {UserSocialLinkRequestBody} [userSocialLinkRequestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putApiUsersMeSocialLinksUpdate: async (type: string, userSocialLinkRequestBody?: UserSocialLinkRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'type' is not null or undefined
            assertParamExists('putApiUsersMeSocialLinksUpdate', 'type', type)
            const localVarPath = `/api/me/social_links/{type}`
                .replace(`{${"type"}}`, encodeURIComponent(String(type)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userSocialLinkRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update my profile information
         * @param {UpdateUserProfileRequestBody} [updateUserProfileRequestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putApiUsersMeUpdateProfile: async (updateUserProfileRequestBody?: UpdateUserProfileRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateUserProfileRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MeApi - functional programming interface
 * @export
 */
export const MeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MeApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Delete profile picture
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteApiUsersMePhotoDelete(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserMeView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteApiUsersMePhotoDelete(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete a social link by type
         * @param {string} type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteApiUsersMeSocialLinksDelete(type: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteApiUsersMeSocialLinksDelete(type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Fetch my user information
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiUsersMeShow(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserMeView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiUsersMeShow(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiUsersMeShowInvitation(code: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FullUserView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiUsersMeShowInvitation(code, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Fetch my social links
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiUsersMeSocialLinksList(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListUserSocialLinksView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiUsersMeSocialLinksList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Upload new profile picture
         * @param {File} [image] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postApiUsersMePhotoUpdate(image?: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserMeView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postApiUsersMePhotoUpdate(image, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Add new social link
         * @param {UserSocialLinkRequestBody} [userSocialLinkRequestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postApiUsersMeSocialLinksCreate(userSocialLinkRequestBody?: UserSocialLinkRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShortUserSocialLinkView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postApiUsersMeSocialLinksCreate(userSocialLinkRequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a social link by type
         * @param {string} type 
         * @param {UserSocialLinkRequestBody} [userSocialLinkRequestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putApiUsersMeSocialLinksUpdate(type: string, userSocialLinkRequestBody?: UserSocialLinkRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShortUserSocialLinkView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putApiUsersMeSocialLinksUpdate(type, userSocialLinkRequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update my profile information
         * @param {UpdateUserProfileRequestBody} [updateUserProfileRequestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putApiUsersMeUpdateProfile(updateUserProfileRequestBody?: UpdateUserProfileRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserMeView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putApiUsersMeUpdateProfile(updateUserProfileRequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MeApi - factory interface
 * @export
 */
export const MeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MeApiFp(configuration)
    return {
        /**
         * 
         * @summary Delete profile picture
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteApiUsersMePhotoDelete(options?: any): AxiosPromise<UserMeView> {
            return localVarFp.deleteApiUsersMePhotoDelete(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a social link by type
         * @param {string} type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteApiUsersMeSocialLinksDelete(type: string, options?: any): AxiosPromise<object> {
            return localVarFp.deleteApiUsersMeSocialLinksDelete(type, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Fetch my user information
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiUsersMeShow(options?: any): AxiosPromise<UserMeView> {
            return localVarFp.getApiUsersMeShow(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiUsersMeShowInvitation(code: string, options?: any): AxiosPromise<FullUserView> {
            return localVarFp.getApiUsersMeShowInvitation(code, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Fetch my social links
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiUsersMeSocialLinksList(options?: any): AxiosPromise<ListUserSocialLinksView> {
            return localVarFp.getApiUsersMeSocialLinksList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Upload new profile picture
         * @param {File} [image] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiUsersMePhotoUpdate(image?: File, options?: any): AxiosPromise<UserMeView> {
            return localVarFp.postApiUsersMePhotoUpdate(image, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add new social link
         * @param {UserSocialLinkRequestBody} [userSocialLinkRequestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiUsersMeSocialLinksCreate(userSocialLinkRequestBody?: UserSocialLinkRequestBody, options?: any): AxiosPromise<ShortUserSocialLinkView> {
            return localVarFp.postApiUsersMeSocialLinksCreate(userSocialLinkRequestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a social link by type
         * @param {string} type 
         * @param {UserSocialLinkRequestBody} [userSocialLinkRequestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putApiUsersMeSocialLinksUpdate(type: string, userSocialLinkRequestBody?: UserSocialLinkRequestBody, options?: any): AxiosPromise<ShortUserSocialLinkView> {
            return localVarFp.putApiUsersMeSocialLinksUpdate(type, userSocialLinkRequestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update my profile information
         * @param {UpdateUserProfileRequestBody} [updateUserProfileRequestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putApiUsersMeUpdateProfile(updateUserProfileRequestBody?: UpdateUserProfileRequestBody, options?: any): AxiosPromise<UserMeView> {
            return localVarFp.putApiUsersMeUpdateProfile(updateUserProfileRequestBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MeApi - object-oriented interface
 * @export
 * @class MeApi
 * @extends {BaseAPI}
 */
export class MeApi extends BaseAPI {
    /**
     * 
     * @summary Delete profile picture
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeApi
     */
    public deleteApiUsersMePhotoDelete(options?: AxiosRequestConfig) {
        return MeApiFp(this.configuration).deleteApiUsersMePhotoDelete(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a social link by type
     * @param {string} type 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeApi
     */
    public deleteApiUsersMeSocialLinksDelete(type: string, options?: AxiosRequestConfig) {
        return MeApiFp(this.configuration).deleteApiUsersMeSocialLinksDelete(type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Fetch my user information
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeApi
     */
    public getApiUsersMeShow(options?: AxiosRequestConfig) {
        return MeApiFp(this.configuration).getApiUsersMeShow(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} code 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeApi
     */
    public getApiUsersMeShowInvitation(code: string, options?: AxiosRequestConfig) {
        return MeApiFp(this.configuration).getApiUsersMeShowInvitation(code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Fetch my social links
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeApi
     */
    public getApiUsersMeSocialLinksList(options?: AxiosRequestConfig) {
        return MeApiFp(this.configuration).getApiUsersMeSocialLinksList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Upload new profile picture
     * @param {File} [image] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeApi
     */
    public postApiUsersMePhotoUpdate(image?: File, options?: AxiosRequestConfig) {
        return MeApiFp(this.configuration).postApiUsersMePhotoUpdate(image, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Add new social link
     * @param {UserSocialLinkRequestBody} [userSocialLinkRequestBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeApi
     */
    public postApiUsersMeSocialLinksCreate(userSocialLinkRequestBody?: UserSocialLinkRequestBody, options?: AxiosRequestConfig) {
        return MeApiFp(this.configuration).postApiUsersMeSocialLinksCreate(userSocialLinkRequestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a social link by type
     * @param {string} type 
     * @param {UserSocialLinkRequestBody} [userSocialLinkRequestBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeApi
     */
    public putApiUsersMeSocialLinksUpdate(type: string, userSocialLinkRequestBody?: UserSocialLinkRequestBody, options?: AxiosRequestConfig) {
        return MeApiFp(this.configuration).putApiUsersMeSocialLinksUpdate(type, userSocialLinkRequestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update my profile information
     * @param {UpdateUserProfileRequestBody} [updateUserProfileRequestBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeApi
     */
    public putApiUsersMeUpdateProfile(updateUserProfileRequestBody?: UpdateUserProfileRequestBody, options?: AxiosRequestConfig) {
        return MeApiFp(this.configuration).putApiUsersMeUpdateProfile(updateUserProfileRequestBody, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Fetch OGP data for a given social link
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiUsersGridLayoutsFetchOgp: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/users/grid_layouts/fetch_ogp`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Fetch my social links
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiUsersGridLayoutsList: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/users/grid_layouts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} username 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiUsersShow: async (username: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'username' is not null or undefined
            assertParamExists('getApiUsersShow', 'username', username)
            const localVarPath = `/api/users/{username}`
                .replace(`{${"username"}}`, encodeURIComponent(String(username)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiUsersShowInvitation: async (code: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'code' is not null or undefined
            assertParamExists('getApiUsersShowInvitation', 'code', code)
            const localVarPath = `/api/users/invitation/{code}`
                .replace(`{${"code"}}`, encodeURIComponent(String(code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} username 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiUsersShowSocialLinks: async (username: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'username' is not null or undefined
            assertParamExists('getApiUsersShowSocialLinks', 'username', username)
            const localVarPath = `/api/users/{username}/social_links`
                .replace(`{${"username"}}`, encodeURIComponent(String(username)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Fetch my social links
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiUsersSocialLinksList: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/users/social_links`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a new card
         * @param {string} id 
         * @param {CardRequestBody} [cardRequestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiUsersGridLayoutsCards: async (id: string, cardRequestBody?: CardRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('postApiUsersGridLayoutsCards', 'id', id)
            const localVarPath = `/api/users/grid_layouts/{id}/cards`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(cardRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Search for user by full_name or username
         * @param {SearchRequestBody} [searchRequestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiUsersSearchUsers: async (searchRequestBody?: SearchRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/users/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(searchRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UsersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Fetch OGP data for a given social link
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiUsersGridLayoutsFetchOgp(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OgpDataView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiUsersGridLayoutsFetchOgp(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Fetch my social links
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiUsersGridLayoutsList(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListUserSocialLinksView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiUsersGridLayoutsList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} username 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiUsersShow(username: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FullUserView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiUsersShow(username, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiUsersShowInvitation(code: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FullUserView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiUsersShowInvitation(code, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} username 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiUsersShowSocialLinks(username: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListUserSocialLinksView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiUsersShowSocialLinks(username, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Fetch my social links
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiUsersSocialLinksList(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListUserSocialLinksView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiUsersSocialLinksList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a new card
         * @param {string} id 
         * @param {CardRequestBody} [cardRequestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postApiUsersGridLayoutsCards(id: string, cardRequestBody?: CardRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postApiUsersGridLayoutsCards(id, cardRequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Search for user by full_name or username
         * @param {SearchRequestBody} [searchRequestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postApiUsersSearchUsers(searchRequestBody?: SearchRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListShortUsersView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postApiUsersSearchUsers(searchRequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UsersApiFp(configuration)
    return {
        /**
         * 
         * @summary Fetch OGP data for a given social link
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiUsersGridLayoutsFetchOgp(options?: any): AxiosPromise<OgpDataView> {
            return localVarFp.getApiUsersGridLayoutsFetchOgp(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Fetch my social links
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiUsersGridLayoutsList(options?: any): AxiosPromise<ListUserSocialLinksView> {
            return localVarFp.getApiUsersGridLayoutsList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} username 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiUsersShow(username: string, options?: any): AxiosPromise<FullUserView> {
            return localVarFp.getApiUsersShow(username, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiUsersShowInvitation(code: string, options?: any): AxiosPromise<FullUserView> {
            return localVarFp.getApiUsersShowInvitation(code, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} username 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiUsersShowSocialLinks(username: string, options?: any): AxiosPromise<ListUserSocialLinksView> {
            return localVarFp.getApiUsersShowSocialLinks(username, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Fetch my social links
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiUsersSocialLinksList(options?: any): AxiosPromise<ListUserSocialLinksView> {
            return localVarFp.getApiUsersSocialLinksList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a new card
         * @param {string} id 
         * @param {CardRequestBody} [cardRequestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiUsersGridLayoutsCards(id: string, cardRequestBody?: CardRequestBody, options?: any): AxiosPromise<object> {
            return localVarFp.postApiUsersGridLayoutsCards(id, cardRequestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Search for user by full_name or username
         * @param {SearchRequestBody} [searchRequestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApiUsersSearchUsers(searchRequestBody?: SearchRequestBody, options?: any): AxiosPromise<ListShortUsersView> {
            return localVarFp.postApiUsersSearchUsers(searchRequestBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI {
    /**
     * 
     * @summary Fetch OGP data for a given social link
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getApiUsersGridLayoutsFetchOgp(options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).getApiUsersGridLayoutsFetchOgp(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Fetch my social links
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getApiUsersGridLayoutsList(options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).getApiUsersGridLayoutsList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} username 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getApiUsersShow(username: string, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).getApiUsersShow(username, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} code 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getApiUsersShowInvitation(code: string, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).getApiUsersShowInvitation(code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} username 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getApiUsersShowSocialLinks(username: string, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).getApiUsersShowSocialLinks(username, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Fetch my social links
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getApiUsersSocialLinksList(options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).getApiUsersSocialLinksList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a new card
     * @param {string} id 
     * @param {CardRequestBody} [cardRequestBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public postApiUsersGridLayoutsCards(id: string, cardRequestBody?: CardRequestBody, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).postApiUsersGridLayoutsCards(id, cardRequestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Search for user by full_name or username
     * @param {SearchRequestBody} [searchRequestBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public postApiUsersSearchUsers(searchRequestBody?: SearchRequestBody, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).postApiUsersSearchUsers(searchRequestBody, options).then((request) => request(this.axios, this.basePath));
    }
}


