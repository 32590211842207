import {
  AuthenticationApi,
  DefaultApi,
  DescriptionsApi,
  DescriptionsMeApi,
  GridLayoutApi,
  GridLayoutMeApi,
  MeApi,
  UsersApi,
} from "@/api/generated";

export default {
  Authentication: new AuthenticationApi(
    undefined,
    process.env.NEXT_PUBLIC_BACKEND_URL || "/",
    undefined
  ),
  Me: new MeApi(
    undefined,
    process.env.NEXT_PUBLIC_BACKEND_URL || "/",
    undefined
  ),
  MyDescriptions: new DescriptionsMeApi(
    undefined,
    process.env.NEXT_PUBLIC_BACKEND_URL || "/",
    undefined
  ),
  User: new UsersApi(
    undefined,
    process.env.NEXT_PUBLIC_BACKEND_URL || "/",
    undefined
  ),
  Descriptions: new DescriptionsApi(
    undefined,
    process.env.NEXT_PUBLIC_BACKEND_URL || "/",
    undefined
  ),
  GridLayout: new GridLayoutApi(
    undefined,
    process.env.NEXT_PUBLIC_BACKEND_URL || "/",
    undefined
  ),
  MyGridLayout: new GridLayoutMeApi(
    undefined,
    process.env.NEXT_PUBLIC_BACKEND_URL || "/",
    undefined
  ),
  Default: new DefaultApi(
    undefined,
    process.env.NEXT_PUBLIC_BACKEND_URL || "/",
    undefined
  ),
};
